import { useState } from 'react';

import ListMenu from '../../container/ListMenu';

const Home = () => {
  const [menu, setMenu] = useState(false);
  const showMenu = () => {
    if(menu){
        setMenu(false);
    }else{
        setMenu(true);
    }
  }

  const hideMenu = () => {
    setMenu(false);
  }

  return (
    <>
      <div className="container">
        <nav className="navbars p-3 border-bottom">
            <div className="container-fluid">
                <div className="navbar-brands">
                    <img src={process.env.PUBLIC_URL+'/images/dwa_logo.jpeg'} alt="dwa_logo.jpeg"/>
                </div>
                {/* <form className="d-flex" role="search">
                    <div id="google_translate_element"></div>
                </form> */}
            </div>
        </nav>
        <div className="p-4">
            <div className="btn-group mb-3">
                <button class="btn btn-secondary dropdown-toggle w-100" type="button" onClick={showMenu}>
                    On This Page
                </button>
                <ul className={menu ? 'dropdown-menu p-2 show' : 'dropdown-menu p-2'}>
                    <ListMenu/>
                </ul>
            </div>
            <div className="row" style={{ marginBottom: 800}} onClick={hideMenu}>
                <div className="col-md-8">
                    <h1 style={{textAlign: 'justify'}}>Introduction and Overview</h1>
                    <p style={{textAlign: 'justify'}}>
                    DWA Coin is a proof-of-stake cryptocurrency. DWA Coin has its
                    unique proof-of-stake algorithm different way of design
                    comparing with any implementation of the coin age concept used
                    by other proof-of-stake cryptocurrencies and is resistant to
                    so-called nothing at stake attacks. A total quantity of 1
                    Trillion available coins were distributed in the genesis block.
                    Curve25519 cryptography is used to provide a balance of security
                    and required processing power, along with the used of SHA256
                    hashing algorithms.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    Generating Blocks in every 60 seconds, on average, by
                    independent accounts on network nodes. Since the full token
                    supply already exists, DWA Coin is redistributed with the
                    inclusion of transaction fees which are awarded to an account
                    when it successfully creates a block. This process is known as
                    forging and is like the mining concept that are used by other
                    cryptocurrencies. Transactions are deemed safe after 60 block
                    confirmations, and with the DWA Coin current architecture and
                    block size cap allows for the processing of up to 720,000
                    transactions per day.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    DWA Coin transactions are based on a series of core transaction
                    types that are not require any of script processing or
                    transaction input/output processing at the network nodes side.
                    These transactions allow core support for: asset exchange, alias
                    registration, encrypted messages, digital goods store, monetary
                    system, voting system, phased transactions, account control,
                    shuffling, account properties, cloud data
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    DWA Coin core can be recognized as an agile, base-layer protocol
                    upon which a limitless range of services, applications, and
                    other currencies can be built.
                    </p>
                    <section id="TechnologyBackground">
                        <h1 style={{textAlign: 'justify'}}>Technology Background</h1>
                        <p style={{textAlign: 'justify'}}>
                        Existing blockchain contracts can only perform simple smart
                        contract computations, the blockchain addresses the transmission
                        and accounting of decentralized value networks. Bitcoin uses a
                        SHA256 Proof of Work (PoW) for consensus on the computational
                        contribution, with each block divided into three parts, namely:
                        </p>
                        <ol>
                            <li className="text-justify pr-50">The hash value of the last block serves as the block header of the current block.</li>
                            <li className="text-justify pr-50">Pending transactions (t1, t2,_ _ _,tn) within the time window T will be hashed into block Coinbase; and</li>
                            <li className="text-justify pr-50">Including miner’s address, which is normally the address of mining pool, the X, as the input of hash functions, will be dispatched by pool server to each miner, who will complete certain computations. The goal is to find H (X; nonce)  Target Difficulty, where nonce is an appended randomized guessing number. The computation result will be verified by the whole network nodes so as to get the reward out of the block to the exact miner’s address, and then the whole network enters the computation of the next block, thus forming a chain eventually. In addition, there are some other information, such as version number, Merkle tree, timestamp, etc.</li>
                        </ol>
                        <p style={{textAlign: 'justify'}}>
                        The whole mining process can be summarized as:
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        SHA256(SHA256(version+prev_hash+merkle_root+ntime+nbits+nonce))
                        <em>&lt; </em>TARGET Ethereum makes use of uncle blocks [4] to
                        improve network concurrency. In particular, both the Ethereum
                        network and the Rootstock [5] network is designed for smart
                        contracts on the chain. The consensus and tamper-proof of make
                        blockchain automatically ensures the enforcement of the
                        contract, contract execution, and funds allocation, thus
                        eliminating the trust and dependence on people or other third
                        parties.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        In the process of optimizing the objective function, various
                        numerical methods are often used to iteratively gradient descent
                        to find the global optimal solution. Large-scale distributed
                        learning often adopts ASGD (asynchronous stochastic gradient
                        descent) to optimize the results. Sometimes, for particular
                        problems, the training can only obtain a sub-optimal solution at
                        a certain distance from the global optimum according to a
                        distribution.
                        </p>
                        <p>
                        Instead of PoW (proof-of-work), DWA Coin relies (PoS)
                        Proof-of-Stake algorithm to reach consensus. But what is the
                        difference?
                        </p>
                        <p>
                        Cryptocurrencies such as Bitcoin which use PoW, require mining.
                        To mine, expensive computer hardware is required and utilized to
                        solve very complex mathematical problems to earn the rewards.
                        It’s considered very expensive.
                        </p>
                        <p>
                        On the other hand, under PoS creators of new blocks are selected
                        deterministically- based on their wealth. This is far much more
                        cost-effective. The other implication here is that since DWA
                        Coin does not require mining, there is a static supply of money.
                        </p>
                        <p>
                        In addition, the network is less susceptible to hacks. To hack a
                        PoS network, hackers would need to invest massive amounts of the
                        currency, and this is likely to devalue their holdings.
                        </p>
                        <p>
                        The DWA Coin ecosystem is fueled by DWA Coin as its virtual
                        currency. DWA Coin tokens can be used for the following:
                        </p>
                        <ul>
                            <li className="text-justify pr-50">To cover transaction fees when transferring DWA Coin between users.</li>
                            <li className="text-justify pr-50">Creating assets representing bonds or ownership of projects.</li>
                            <li className="text-justify pr-50">Deploy decentralized polls within a Blockchain.</li>
                            <li className="text-justify pr-50">As a digital currency for exchange during ordinary purchases.</li>
                        </ul>
                    </section>

                    <section id="Purpose">
                        <h1 id="Purpose">Purpose</h1>
                        <p style={{textAlign: 'justify'}}>
                        The value of Bitcoins is constantly fluctuating according to
                        demand. This constant fluctuation will cause Bitcoin accepting
                        sites to continually change prices. It will also cause a lot of
                        confusion if a refund for a product is being made. For example,
                        if a t-shirt was initially bought for 1.5 BTC, and returned a
                        week later, should 1.5 BTC be returned, even though the
                        valuation has gone up, or should the new amount (calculated
                        according to current valuation) be sent? Which currency should
                        BTC tied to when comparing valuation? These are still important
                        questions that the Bitcoin community still has no consensus
                        over.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        DWA Coin purpose is to develop more stable coin environment. In
                        which every coin of DWA Coin will be backed up by real asset
                        that are offered in the marketplace. One which is trade at the
                        marketplace is the tokens not the coin itself. Every transaction
                        related to token trading will be subject for a fee in the form
                        of DWA Coin coins.
                        </p>
                    </section>
                    <h1>Coin Distribution</h1>

                    <section id="CoinAllocations">
                        <h2>Coin Allocations</h2>
                        <p>
                        <em>10 (Ten) Trillion coins</em> are generated at the same time
                        of Genesis block creation.
                        </p>
                        <p>
                        <em>7 (Seven) Genesis Accounts</em> also created in purpose to
                        make sure the system is well and stable running operation in the
                        early stage of the DWA release. The 7 Genesis Account is
                        separated to 3 function such as:
                        </p>
                        <ul>
                            <li>Reserve Account</li>
                            <li>Main Distribution Account</li>
                            <li>Forger Account</li>
                        </ul>
                        <p>
                        From the total of 10 Trillion coins,<em> 6 Trillion coins</em> are
                        stored in
                        <em>Reserve Account, 2 Trillion Coin ready for distribution</em>
                        and total of <em>2 Trillion coins</em> are reserved for
                        <em>Forger Accounts</em>. In other words, Each Forger Account
                        reserved 400 Billion coins.
                        </p>
                        <p>Coin Distribution Ration</p>
                        <div>
                        <table width="100%" className="mb-3">
                            <tr>
                                <td className="ps-2">Available (distributed for sell)</td>
                                <td className="text-center"><strong>80%</strong></td>
                            </tr>
                            <tr>
                                <td className="ps-2">
                                    Reserve for forging account (not distributed for sell)
                                </td>
                                <td className="text-center">
                                    <strong>20%</strong>
                                </td>
                            </tr>
                        </table>
                        </div>
                        <p>
                        Total of coin quantity will be remining at 1 Trillion coins,
                        nonadditional coin will be added in the future.
                        </p>
                    </section>

                    <section id="GenesisAccounts">
                    <h2>Genesis Accounts</h2>
                        <p style={{textAlign: 'justify'}}>
                        In order to maintain transparency, DWA Coin reveal information
                        regarding the genesis account and its use as follows.
                        </p>

                        <li><strong>RESERVE Account</strong></li>
                        <p style={{textAlign: 'justify'}}>1.&nbsp; &nbsp; DWA-MLLK-D9EB-TARE-3AXWY</p>

                        <li><strong>MAIN Distribution Account</strong></li>
                        <p style={{textAlign: 'justify'}}>1.&nbsp; &nbsp; DWA-7MGQ-3W39-7BUZ-E5MP9</p>

                        <li><strong>FORGER Account</strong></li>

                        <p style={{textAlign: 'justify'}}>1.&nbsp; &nbsp; DWA-QXJP-BFD3-VMP4-9JGA7</p>
                        <p style={{textAlign: 'justify'}}>2.&nbsp; &nbsp; DWA-RHTW-WJZW-2FMA-BV8X2</p>
                        <p style={{textAlign: 'justify'}}>3.&nbsp; &nbsp; DWA-ZJJG-8J83-BPGW-B84J6</p>
                        <p style={{textAlign: 'justify'}}>4.&nbsp; &nbsp; DWA-B7VU-3J48-ZTFV-DU9PH</p>
                        <p style={{textAlign: 'justify'}}>5.&nbsp; &nbsp; DWA-ZZ4W-C3U8-XUMG-38NB6</p>
                    </section>


                    <h1 style={{textAlign: 'justify'}}>Core technologies</h1>
                    
                    <section id="ProofofStake">
                    <h2 style={{textAlign: 'justify'}}>Proof of Stake</h2>
                    <p style={{textAlign: 'justify'}}>
                    Proof-of-Stake (PoS) is a system on the same basis as
                    Proof-of-Work (PoW), but has several advantages, including the
                    acceleration of a validation process. This type of algorithm is
                    used to reach a distributed consensus, which is chosen through
                    various combinations of random choices based on the amount of
                    Hash power the miner has. The application of PoS algorithm in
                    cryptography uses computationally intensive puzzles for
                    transaction validation and the creation of new blocks.
                    Proof-of-Stake has been favored as a more flexible consensus
                    system with modifications from developers. Nxt and BlackCoin use
                    make modifications in the formula to find the lowest hash value
                    combined. That is, the division of the mining process
                    (validation) can be done evenly, not like PoW that implements
                    the greater the Hash power it has, the greater the process
                    received. Proof-of-Stake can sort out which network nodes that
                    have not been rationed for a long time, so that the node can
                    come first. However, after the node has 1 process, the time will
                    be reset, and the queue will start again. Miners can be
                    determined deterministically by the algorithm based on the age
                    of the node. The higher the age of the node, the higher the
                    chance of getting a turn for the validation process. After
                    getting 1 order, the age time resets back to "0", and will
                    re-enter the initial queue. Like PoW, PoS is also implemented as
                    a system to ward off various digital attacks that may occur,
                    especially Denial of Services and Spamming. Cost is exorbitant
                    to try to carry out attacks on networks that adopt both PoS and
                    PoW systems. PoS is broadly faster and more efficient than PoW
                    systems, because technically, anyone can do mining. PoS also
                    offers a linear scale relative to the percentage of blocks that
                    can be confirmed with its Mining Rig, so the process will be
                    adjusted to the power quota they have.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    In the traditional Proof-of-Work (PoW) model used by most
                    cryptocurrencies, network security is provided by peers doing
                    work. They deploy their resources (computation/processing time)
                    to reconcile double-spending transactions, and to impose an
                    extraordinary cost on those who would attempt to reverse
                    transactions. Tokens are awarded to peers in exchange for work,
                    with the frequency and amount varying with each
                    cryptocurrency’s operational parameters. This process is
                    known as mining. The frequency of block generation, which
                    determines each cryptocurrency’s available mining reward,
                    is generally intended to stay constant. As a result, the
                    difficulty of the required work for earning a reward must
                    increase as the work capacity of the network increases.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    As a PoW network becomes stronger, there is less incentive for
                    an individual peer to support the network, because their
                    potential reward is split among a greater number of peers. In
                    search of profitability, miners keep adding resources in the
                    form of specialized, proprietary hardware that requires
                    significant capital investment and high ongoing energy demands.
                    As time progresses, the network becomes more and more
                    centralized as smaller peers (those who can do less work) drop
                    out or combine their resources into pools.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    In the Proof of Stake model used by DWA Coin, network security
                    is governed by peers having a <em>stake</em> in the network. The
                    incentives provided by this algorithm do not promote
                    centralization in the same way that Proof of Work algorithms do,
                    and data shows that the DWA Coin network has remained highly
                    decentralized since its inception: a large number of unique
                    accounts are contributing blocks to the network, and the top
                    five accounts have generated 42% of the total number of blocks.
                    </p>
                    </section>


                    <section id="DWACoin’sProofofStakeModel">
                    <h3 style={{textAlign: 'justify'}}>
                    DWA Coin’s Proof of Stake Model
                    </h3>
                    <p style={{textAlign: 'justify'}}>
                    DWA Coin use the system that the more tokens that are held in
                    the account, the greater the chance that account will earn the
                    right to generate a block. The total reward received are located
                    within the block represent the summary of the transaction fees
                    as the result of block generation. No new tokens generated by
                    DWA Coin as a result of block creation. Redistribution of DWA
                    Coin takes place as a result of block generators receiving
                    transaction fees, so the term forging is used instead of mining.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    Generation of the subsequent blocks are based on verifiable,
                    unique, and almost-unpredictable information from the previous
                    block. Blocks are linked by virtue of these connections,
                    creating a chain of blocks (and transactions) that can be traced
                    all the way back to the genesis block.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    Block generation time is targeted at 60 seconds.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    There is always a concern on the security of the blockchain in
                    Proof of Stake systems. The following basic principles apply to
                    DWA Coin Proof of Stake algorithm:
                    </p>
                    <ul>
                    <li>
                        A <em>cumulative difficulty</em> value is stored as a
                        parameter in each block, and new level of difficulty are
                        applied to each subsequent block based on the previous blocks
                        value. In case of ambiguity, the network achieves consensus by
                        selecting the block or chain fragment with the highest
                        cumulative difficulty.
                    </li>
                    <li>
                        Tokens must be stationary within an account for 1,440 blocks
                        before they can contribute to the block generation process,
                        this mechanism to prevent account holders from moving their
                        stake from one account to another in purpose of manipulating
                        their probability of block generation. Tokens that meet this
                        criterion contribute to an account’s
                        <em>effective balance</em>, and this balance is used to
                        determine forging probability.
                    </li>
                    <li>
                        Peers allow chain reorganization of no more than 720 blocks
                        behind the current block height. This is aimed to keep an
                        attacker from generating a new chain all the way from the
                        genesis block, any block submitted at a height lower than this
                        threshold is rejected.
                    </li>
                    <li>
                        Transactions are deemed safe once they are encoded into a
                        block that is 10 blocks behind the current block height. This
                        is because the extremely low probability of any account taking
                        control of the blockchain by generating its own chain of
                        blocks.
                    </li>
                    </ul>
                    </section>

                    <section id="Tokens">
                    <h1 style={{textAlign: 'justify'}}>Tokens</h1>
                    <p style={{textAlign: 'justify'}}>
                    In DWA Coin, tokens are the object of trade not the coin itself.
                    Every transaction related to tokens purchase, sell, transfer,
                    exchange, etc will be subject for certain fee in the form of DWA
                    coins. The minimum transaction fee is staring from 0.1 DWA per
                    transaction. The fee will be adjust based on the transaction
                    type and some other transaction property that are set during the
                    transaction creation.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    Tokens can be treated as the digital unit value of real asset
                    that are trade in the marketplace. In which the asset itself
                    could be in the form of property, cars, gold, etc. the
                    marketplace producer who is divining which kind of asset they
                    are trading in and the digital unit value of the asset
                    itself.&nbsp;
                    </p>
                    </section>

                    <section id="NetworkNodes">
                        <h1 style={{textAlign: 'justify'}}>Network Nodes</h1>
                        <p style={{textAlign: 'justify'}}>
                        Any device that is contributing transaction or block data to the
                        network is recognize as a <em>node</em> on the DWA Coin network.
                        All device running the DWA Coin software is seen as a node.
                        Nodes are sometimes referred to as "Peers".
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        There are two types of nodes: <em>hallmarked</em> and
                        <em>normal</em>. A hallmarked node is a node that is tagged with
                        an encrypted token from an account private key; this token can
                        be decoded to reveal a specific DWA Coin account address and
                        balance that are associated with a node. This made a hallmarked
                        node have greater level of accountability and trust, and so more
                        trusted than non-hallmarked nodes on the network. The larger the
                        balance of an account tied to a hallmarked node; the more trust
                        is given to that node. While an attacker might wish to hallmark
                        a node in order to gain trust within the network and then use
                        that trust for malicious purposes; the barrier to entry (cost of
                        DWA Coin required to build adequate trust) discourages such
                        abuse.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        Both transactions and block information can be process and
                        broadcast by each node on the DWA Coin network. Each Blocks
                        received from other nodes are validated, and in cases block
                        validation fails, nodes may be blacklisted temporarily to
                        prevent the propagation of invalid block data.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        Each node features a built-in DDOS (Distributed Denial of
                        Services) defense mechanism which restricts the number of
                        network requests from any other node to 30 per second.
                        </p>
                    </section>

                    <section id="Blocks">
                        <h1 style={{textAlign: 'justify'}}>Blocks</h1>
                        <p style={{textAlign: 'justify'}}>
                        The ledger of DWA Coin transactions is built and stored in a
                        linked series of blocks, known as a blockchain. This ledger
                        store permanent record of transactions that are done in the DWA
                        Coin system and establishes the order of transactions based on
                        the occurred timestamp. Every nodes in the DWA Coin network keep
                        a copy of the recorded transactions, and every account that is
                        unlocked on a node (by supplying the account private key) has
                        the ability to generate blocks, as long as at least one incoming
                        transaction to the account has been confirmed 60 times. Any
                        account that meets these criteria is referred to as an active
                        account.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        Each block contains up to 500 transactions, prefaced by a block
                        header that store identifying parameters. Each transaction in a
                        block is represented by common transaction data, specific
                        transaction types also include other transaction detail
                        attachment, and certain transactions may include one or more
                        additional appendices. The maximum block size is 42KB. All
                        blocks contain the following parameters:
                        </p>
                        <ul>
                            <li>A block version, block height value, and block identifier</li>
                            <li>A block timestamp, expressed in milliseconds since the genesis block</li>
                            <li>The ID of the account that generated the block, as well as that accounts public key</li>
                            <li>The ID and hash of the previous block the number of transactions stored in the block</li>
                            <li>The total amount of DWA Coin represented by transactions and fees in the block</li>
                            <li>Transaction data for all transactions included in the block, including their transaction IDs</li>
                            <li>The payload length of the block, and the hash value of the block payload</li>
                            <li>The block’s generation signature</li>
                            <li>A signature for the entire block</li>
                            <li>Block Creation (Forging)</li>
                        </ul>
                    </section>

                    <section id="BlockCreation(Forging)">
                        <h2 style={{textAlign: 'justify'}}>Block Creation (Forging)</h2>
                        <p style={{textAlign: 'justify'}}>
                        In DWA Coin, to determining which account is eligible to
                        generate a block will be based on Three values, which account
                        earns the right to generate a block, and which block is taken to
                        be the authoritative one in times of conflict: base target
                        value, target value and cumulative difficulty.
                        </p>
                    </section>

                    <section id="BaseTargetValue">
                        <h3 style={{textAlign: 'justify'}}>Base Target Value</h3>
                        <p style={{textAlign: 'justify'}}>
                        All the active DWA Coin accounts compete by attempting to
                        generate a hash value that is lower than a given base target
                        value in order to win the right to forge (generate) a block.
                        This base target value varies from block to block and is derived
                        from the previous block base target multiplied by the amount of
                        time that was required to generate that block using a formula
                        that ensures 60 seconds average block time.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The calculation is based on the following constants:
                        </p>
                        <ul>
                        <li>
                            MAXRATIO = 67 - max ratio by which the target is decreased
                            when block time is larger than 60 seconds.
                        </li>
                        <li>
                            MINRATIO = 53 - min ratio by which the target is increased
                            when block time is smaller than 60 seconds.
                        </li>
                        <li>γ (gamma) = 0.64</li>
                        </ul>
                        <p style={{textAlign: 'justify'}}>And the following variables:</p>
                        <ul>
                        <li>S - average block time for the last 3 blocks</li>
                        <li>Tp - previous base target</li>
                        <li>Tb - calculated base target</li>
                        </ul>
                        <p style={{textAlign: 'justify'}}>
                        The base target is calculated as follows:
                        </p>
                        <ul>
                        <li>If S&gt;60 <img src={process.env.PUBLIC_URL+'/images/1.jpg'} alt="1.jpg"/></li>
                        </ul>
                        <ul>
                        <li>
                            Else &nbsp; <img src={process.env.PUBLIC_URL+'/images/2.jpg'} alt="2.jpg"/>
                        </li>
                        </ul>
                    </section>

                    <section id="TargetValue">
                        <h3 style={{textAlign: 'justify'}}>Target Value</h3>
                        <p style={{textAlign: 'justify'}}>
                        Each account calculates its own target value, based on its
                        current effective stake. This value is:
                        </p>
                        <img src={process.env.PUBLIC_URL+'/images/3.jpg'} alt="3.jpg"/>
                        <p style={{textAlign: 'justify'}}>where:</p>
                        <p style={{textAlign: 'justify'}}>T is the new target value</p>
                        <p style={{textAlign: 'justify'}}>Tb is the base target value</p>
                        <p style={{textAlign: 'justify'}}>
                        S is the time since the last block, in seconds
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        Be is the effective balance of the account
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        As can be seen from the formula, the target value grows with
                        each second that passes since the timestamp of the previous
                        block. The maximum target value is 1.53722867 x 1017 and the
                        minimum target value is one half of the previous blocks base
                        target value.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        This target value and the base target value are the same for all
                        accounts attempting to forge on top of a specific block. The
                        only account-specific parameter is the effective balance
                        parameter.
                        </p>
                    </section>

                    <section id="Cumulativedifficulty">
                        <h3 style={{textAlign: 'justify'}}>Cumulative difficulty</h3>
                        <p style={{textAlign: 'justify'}}>
                        <span style={{color: '#111111'}}>
                            In the most common blockchain implementation, we chose always
                            the “longest” blockchain to be the valid. This
                            must change now that difficulty is introduced. For now, on the
                            “correct” chain is not the “longest”
                            chain, but the chain with the 
                        </span>
                        <span style={{color: '#111111'}}>
                            <strong> most cumulative difficulty</strong>
                        </span>
                        <span style={{color: '#111111'}}>
                            . In other words, the correct chain is the chain which
                            required most resources (= hashRate * time) to produce.
                        </span>
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        <span style={{color: '#111111'}}>
                            To get the cumulative difficulty of a chain we calculate 
                        </span>
                        <span style={{color: '#111111'}}> 2^difficulty</span>
                        <span style={{color: '#111111'}}>
                            for each block and take a sum of all those numbers. We must
                            use the 
                        </span>
                        <span style={{color: '#111111'}}> 2^difficulty</span>
                        <span style={{color: '#111111'}}>
                            as we chose the difficulty to represent the number of zeros
                            that must prefix the hash in binary format. For instance, if
                            we compare the difficulties of 5 and 11, it requires 2^ (11-5)
                            = 2^6 times more work to find a block with latter difficulty.
                        </span>
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        <span style={{color: '#111111'}}>
                            In the below example, the “Chain B” is the
                            “correct” chain although it has fever
                            blocks:&nbsp;
                        </span>
                        </p>
                        <img src={process.env.PUBLIC_URL+'/images/4.jpg'} alt="4.jpg"/>
                        <p style={{textAlign: 'justify', paddingTop: 10}}>
                        <span style={{color: '#111111'}}>
                            Only the difficulty of the block matters, not the actual hash
                            (given the hash is valid). For example, if the difficulty is 4
                            and the block hash is 000000a34c… (= also satisfying
                            the difficulty of 6), only the difficulty of 4 is considered
                            when calculating the cumulative difficulty.
                        </span>
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        <span style={{color: '#111111'}}>
                            This property is also known as “Nakamoto
                            consensus” and it is one of the most important
                            inventions Satoshi made, when s/he invented Bitcoin. In case
                            of forks, miners must choose on which chain the they decide to
                            put their current resources (= hashRate). As it is in the
                            interest of the miners to produce such block that will be
                            included in the blockchain, the miners are incentivized to
                            eventually to choose the same chain.
                        </span>
                        </p>
                    </section>


                    <section id="TheForgingAlgorithm">
                        <h2 style={{textAlign: 'justify'}}>The Forging Algorithm</h2>
                        <p style={{textAlign: 'justify'}}>
                        Each block on the chain has a <em>generation signature</em>
                        parameter.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        An active account digitally signs the generation signature of
                        the previous block with its own public key to participate in the
                        block forging process. In which a 64-byte signature are created,
                        which is then hashed using SHA256. The first 8 bytes of the
                        resulting hash are converted to a number, known as account
                        <em>hit</em>.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The hit then compared to the current target value. the next
                        block can be generated if the computed hit is lower than the
                        target. As noted in the target value formula, the target value
                        increases in every second of process. Even if the active
                        accounts on the network is less, block will be successfully
                        generated by one of them because of the target value will become
                        very large. Therefore, you will be able to calculate the time
                        that needed by any account to forge a block by comparing the
                        account hit value to the target value.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The last point is significant. Since any node can query the
                        effective balance for any active account, iteration through all
                        active accounts in order to determine their individual hit value
                        is possible. This means it is possible to reasonable accuracy
                        predict, which account will be winning the right to forge a
                        block. A balance shifting attack cannot be mounted by moving
                        stake to an account that will generate the next block, since DWA
                        Coin stake must be stationary for 1440 blocks before it can
                        contribute to forging (via the effective balance value).
                        Interestingly, the new base target value for the next block
                        cannot be reasonably predicted, so the nearly deterministic
                        process of determining who will forge the next block becomes
                        increasingly stochastic as attempts are made to predict future
                        blocks. This feature of the DWA Coin forging algorithm helps
                        form the basis for the development and implementation of the
                        Transparent Forging algorithm. Since this algorithm has not yet
                        completely been implemented, and because its implications on the
                        DWA Coin network are significant, it will be outlined in a
                        separate paper.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        When an active account wins the right to generate a block, it
                        then bundles up to 255 unconfirmed transactions into a new block
                        and populates the block with all its required parameters. Then
                        it’s broadcast the block to the network as a candidate
                        block for the blockchain.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The payload value, generating account, and all the signatures on
                        each block can be verified by all network nodes who receive it.
                        In a situation where multiple blocks are generated, nodes will
                        select the block with the highest cumulative difficulty value as
                        the authoritative block. As block data is shared between peers,
                        forks (non-authoritative chain fragments) are detected and
                        dismantled by examining the chains cumulative difficulty values
                        stored in each fork.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        A node which receive a valid block representing a chain with
                        larger cumulative difficulty than its own, will determine the
                        highest common block between its own chain and the chain
                        represented by the new block, then remove its own blocks from
                        the chain down to the common block and undo any side effects of
                        these blocks then build its own chain based on blocks received
                        from other nodes
                        </p>
                    </section>

                    <section id="Balanceleasing">
                        <h2 style={{textAlign: 'justify'}}>Balance leasing</h2>
                        <p style={{textAlign: 'justify'}}>
                        To be able to increase effective balance parameter to get bigger
                        chance to wining the right to forge, it is possible for an
                        active account to loan forging power from another account
                        without giving up control of the tokens associated with the
                        respective account. An account owner may temporarily reduce its
                        accounts effective balance to zero, adding it to the effective
                        balance of another account. The targeted account forging power
                        is increased for a certain number of blocks specified by the
                        original account owner, after which the effective balance is
                        returned to the original account. The account which leased its
                        forging power, does not need to reveal its passphrase in order
                        to participate in forging new blocks. Only the lessee account
                        needs to reveal its passphrase and this account can poses much
                        smaller balance so that in case its passphrase is stolen the
                        loss is minimal. Leasing balance does not affect the
                        functionality of the lessor account except its ability to forge.
                        </p>
                    </section>

                    <section id="Accounts">
                        <h1 style={{textAlign: 'justify'}}>Accounts</h1>
                        <p style={{textAlign: 'justify'}}>
                        <em>Brain wallet</em> as part of DWA Coin design: all accounts
                        are stored on the network, with <em>private keys</em> for each
                        account address directly derived from each <em>passphrase</em>
                        using a combination of SHA256 and Curve25519 operations.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        Each account is represented by a 64-bit number, and this number
                        is expressed as an <em>account address</em> using a Reed-Solomon
                        error-correcting notation that allows for <em>detection</em> of
                        up to four errors in an account address, or <em>correction</em>
                        of up to two errors. This practically eliminates the risk that a
                        typo in account address would result in loss of funds. Account
                        addresses are always prefaced by a <em>DWA Coin -</em> prefix,
                        making DWA Coin account addresses easily recognizable and
                        distinguishable from address formats used by other blockchains.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The Reed-Solomon-encoded account address associated with a
                        secret passphrase is generated as follows:
                        </p>
                        <ol>
                        <li>
                            The secret passphrase is hashed with SHA256 to derive the
                            accounts <em>private key</em>.
                        </li>
                        <li>
                            The private key is encrypted with Curve25519 to derive the
                            accounts <em>public key</em>.
                        </li>
                        <li>
                            The public key is hashed with SHA256 to derive the
                            <em>account ID</em>.
                        </li>
                        <li>
                            The first 64 bits of the account ID are the
                            <em>visible account number</em>.
                        </li>
                        <li>
                            Reed-Solomon encoding of the visible account number, prefixed
                            with DWA Coin, generates the <em>account address</em>.
                        </li>
                        </ol>
                        <p style={{textAlign: 'justify'}}>
                        When an account is accessed by a secret passphrase for the very
                        first time, it is not secured by a public key. When the first
                        outgoing transaction from an account is made, the 256-bit public
                        key derived from the passphrase is stored on the blockchain, and
                        this secures the account. The address space for public keys
                        (2256) is larger than the address space for account numbers
                        (264), so there is no one-to-one mapping of passphrases to
                        account numbers and collisions are possible. These collisions
                        are detected and prevented in the following way: once a specific
                        passphrase is used to access an account, and that account is
                        secured by a 256-bit public key, no other public-private key
                        pair is permitted to access that account number.
                        </p>
                    </section>

                    <section id="AccountBalanceProperties">
                        <h2 style={{textAlign: 'justify'}}>Account Balance Properties</h2>
                        <p style={{textAlign: 'justify'}}>
                        Several different types of balances are available for each DWA
                        Coin account. Each type serves a different purpose, and many of
                        these values are checked as part of transaction validation and
                        processing.
                        </p>
                        <ul>
                        <li>
                            The <em>effective balance</em> of an account is used as the
                            basis for an account's forging calculations. An account's
                            effective balance consists of all tokens that have been stay
                            in that account for 1440 blocks. In addition, the Account
                            Leasing feature allows an account's effective balance to be
                            lease to another account for a temporary period. The account
                            effective balance is calculated from the
                            <em>confirmed balance</em> by reducing all balance additions
                            during the last 1440 blocks.
                        </li>
                        <li>
                            The <em>guaranteed balance</em> of an account consists of all
                            tokens that have been stay in an account for 1440 blocks.
                            Unlike the effective balance, this balance cannot be assigned
                            to any other account.
                        </li>
                        <li>
                            The <em>confirmed balance</em> of an account accounts for all
                            transactions that have had at least one confirmation.
                        </li>
                        <li>
                            The <em>unconfirmed balance</em> of an account is the one that
                            is displayed in DWA Coin clients. It represents the confirmed
                            balance of an account, minus the tokens involved in
                            unconfirmed, sent transactions or locked by specific
                            transaction types such as Currency Reserve Increase and
                            Shuffling transactions or locked by phased transactions not
                            applied or cancelled yet.
                        </li>
                        <li>
                            The <em>forged balance</em> of an account shows the total
                            amount of DWA Coin that have been earned as a result of
                            successfully forging blocks.
                        </li>
                        <li>
                            Confirmed and unconfirmed asset quantities and currency units
                            are also tracked by each account holdings.
                        </li>
                        </ul>
                    </section>

                    <section id="Transactions">
                        <h1 style={{textAlign: 'justify'}}>Transactions</h1>
                        <p style={{textAlign: 'justify'}}>
                        To be able to get improve to their state or balance, DWA Coin
                        account must commit transaction. Each transaction performs only
                        one function, the record of which is permanently stored on the
                        network once that transaction has been included in a block.
                        </p>
                    </section>

                    <section id="TransactionFees">
                        <h2 style={{textAlign: 'justify'}}>Transaction Fees</h2>
                        <p style={{textAlign: 'justify'}}>
                        Transaction fees are the primary mechanism through which DWA
                        Coin are recirculated back into the network. Every transaction
                        requires a minimum fee. When an DWA Coin account forges a block,
                        all the transaction fees included in that block are awarded to
                        the forging account as a reward. Unlike with other blockchains,
                        minimum transaction fees are enforced by the blockchain
                        therefore transactions which does not specify a fee larger than
                        the minimal fee for this transaction type won't be accepted by
                        nodes.
                        </p>
                    </section>

                    <section id="TransactionConfirmations">
                        <h2 style={{textAlign: 'justify'}}>Transaction Confirmations</h2>
                        <p style={{textAlign: 'justify'}}>
                        All DWA Coin transactions are considered <em>unconfirmed</em>
                        until they are included in a valid network block. Newly created
                        blocks are distributed to the network by the node (and
                        associated account) that creates them, and a transaction that is
                        included in a block is considered as having received one
                        confirmation. As subsequent blocks are added to the existing
                        blockchain, each additional block adds one more confirmation to
                        the number of confirmations for a transaction.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        If a transaction is not included in a block before its deadline,
                        it expires and is removed from the transaction pool.
                        </p>
                    </section>

                    <section id="TransactionDeadlines">
                        <h2 style={{textAlign: 'justify'}}>Transaction Deadlines</h2>
                        <p style={{textAlign: 'justify'}}>
                        Every transaction contains a deadline parameter, set to several
                        minutes from the time the transaction is submitted to the
                        network. The default deadline is 1440 minutes (24 hours). A
                        transaction that has been broadcast to the network but has not
                        been included in a block yet is referred to as an
                        <em>unconfirmed transaction</em>.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        If a transaction has not been included in a block before the
                        transaction deadline expires, the transaction is removed from
                        the network.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        Transactions may be left unconfirmed until their deadline
                        expire, because they are permanently invalid or malformed, or
                        because they do not meet certain temporary conditions such as
                        sufficient balances, or because blocks are being filled with
                        transactions that have offered to pay higher transaction fees.
                        </p>
                    </section>
                    
                    <section id="TransactionTypes">
                    <h2 style={{textAlign: 'justify'}}>Transaction Types</h2>
                    <p style={{textAlign: 'justify'}}>
                    Categorizing DWA Coin transactions into types and subtypes
                    allows for modular growth and development of the DWA Coin
                    protocol without creating dependencies on other base functions.
                    As features are added to the DWA Coin core, new transaction
                    types and subtypes can be added to support them.
                    </p>
                    <p style={{textAlign: 'justify'}}>
                    Multiple transaction types and associated subtypes are supported
                    by DWA Coin. Each type dictates a given transactions required
                    and optional parameters, as well as its processing method. A
                    complete list of all transaction types and sub types is out of
                    the scope of this document.
                    </p>
                    </section>

                    <section id="TransactionCreationandProcessing">
                        <h2 style={{textAlign: 'justify'}}>Transaction Creation and Processing</h2>
                        <p style={{textAlign: 'justify'}}>
                        The details of creating and processing a DWA Coin transaction
                        are as follows:
                        </p>
                        <ol>
                        <li>
                            The sender specifies parameters for the transaction. Types of
                            transactions vary, and the desired type is specified at
                            transaction creation, but several parameters must be specified
                            for all transactions:
                        </li>
                        <ul>
                            <li>private key for the sending account</li>
                            <li>specified fee for the transaction</li>
                            <li>deadline for the transaction</li>
                            <li>an optional referenced transaction</li>
                        </ul>
                        <li>
                            All values for the transaction inputs are checked. For
                            example, mandatory parameters must be specified; fees cannot
                            be less than the minimum fee for this transaction type; a
                            transaction deadline cannot be less than one minute into the
                            future; if a referenced transaction is specified, then the
                            current transaction cannot be processed until the referenced
                            transaction has been processed.
                        </li>
                        <li>
                            If no exceptions are thrown as a result of parameter checking:
                        </li>
                        <ul>
                            <li>
                            The public key for the generating account is computed using
                            the supplied secret passphrase
                            </li>
                            <li>
                            Account information for the generating account is retrieved,
                            and transaction parameters are further validated:
                            </li>
                            <ul>
                            <li>The sending account’s balance cannot be zero</li>
                            <li>
                                The sending account’s <em>unconfirmed balance</em>
                                must not be lower than the transaction amount plus the
                                transaction fee
                            </li>
                            </ul>
                        </ul>
                        <li>
                            If the sending account has enough funds for the transaction:
                        </li>
                        <ul>
                            <li>
                            A new transaction is created, with a type and subtype value
                            set to match the kind of transaction being made. All
                            specified parameters are included. A unique transaction ID
                            is generated with the creation of the object
                            </li>
                            <li>
                            The transaction is signed using the sending account’s
                            private key
                            </li>
                            <li>
                            The encrypted transaction data is placed within a message
                            instructing network peers to process the transaction
                            </li>
                            <li>
                            The transaction is broadcast to all peers on the network
                            </li>
                            <li>The server responds with a result code:</li>
                            <ul>
                            <li>
                                the transaction ID, if the transaction creation was
                                successful
                            </li>
                            <li>
                                an error code and error message if any of the parameter
                                checks fail.
                            </li>
                            </ul>
                        </ul>
                        </ol>
                    </section>

                    <section id="CryptographicFoundations">       
                        <h1 style={{textAlign: 'justify'}}>Cryptographic Foundations</h1>
                        <p style={{textAlign: 'justify'}}>
                        Curve25519 algorithm are use on Key exchange in DWA Coin, which
                        generates a shared secret key using a fast, efficient,
                        high-security elliptic-curve Diffie-Hellman function. The
                        algorithm was first demonstrated by Daniel J. Bernstein in 2006.
                        DWA Coin Java-based implementations were reviewed by DoctorEvil
                        in March 2014.
                        </p>
                        {/* <p style={{textAlign: 'justify'}}>
                        Message signing in DWA Coin is implemented using the
                        Elliptic-Curve Korean Certificate-based Digital Signature
                        Algorithm (EC-KCDSA), specified as part of IEEE P1363a by the
                        KCDSA Task Force team in 1998.
                        </p> */}
                        <p style={{textAlign: 'justify'}}>
                        Message signing in DWA Coin is implemented using the Elliptic-Curve Digital Signature Algorithm (ECDSA).
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        Both algorithms were chosen for their balance of speed and
                        security for a key size of only 32 bytes.
                        </p>
                    </section>
                    <section id="EncryptionAlgorithm">
                        <h2 style={{textAlign: 'justify'}}>
                        Encryption Algorithm
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        When Alice sends an encrypted plaintext to Bob, she:
                        </p>
                        <ol>
                        <li>Calculates a shared secret:</li>
                        <ul>
                            <li>
                            shared_secret = Curve25519(Alice_private_key,
                            Bob_public_key)
                            </li>
                        </ul>
                        <li>Calculates N seeds:</li>
                        <ul>
                            <li>
                            seedn = SHA256(seedn-1), where seed0 = SHA256(shared_secret)
                            </li>
                        </ul>
                        <li>Calculates N keys:</li>
                        <ul>
                            <li>
                            keyn = SHA256(Inv(seedn)), where Inv(X) is the inversion of
                            all bits of X
                            </li>
                        </ul>
                        <li>Encrypts the plaintext:</li>
                        <ul>
                            <li>ciphertext[n] = plaintext[n] XOR keyn</li>
                        </ul>
                        </ol>
                        <p style={{textAlign: 'justify'}}>
                        Upon receipt Bob decrypts the ciphertext:
                        </p>
                        <ol>
                        <li>Calculates a shared secret:</li>
                        <ul>
                            <li>
                            shared_secret = Curve25519(Bob_private_key,
                            Alice_public_key)
                            </li>
                        </ul>
                        <li>Calculates N seeds (this is identical to Alices step):</li>
                        <ul>
                            <li>
                            seedn = SHA256(seedn-1), where seed0 = SHA256(shared_secret)
                            </li>
                        </ul>
                        <li>Calculates N keys (this is identical to Alices step):</li>
                        <ul>
                            <li>
                            keyn = SHA256(Inv(seedn)), where Inv(X) is the inversion of
                            all bits of X
                            </li>
                        </ul>
                        <li>Decrypts the ciphertext:</li>
                        <ul>
                            <li>plaintext[n] = ciphertext[n] XOR keyn</li>
                        </ul>
                        </ol>
                    </section>

                    <h1 style={{textAlign: 'justify'}}>Core Features</h1>

                    <section id="AdvancedJavaScriptclient">
                        <h2 style={{textAlign: 'justify'}}>
                        Advanced JavaScript client
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        A user-friendly client application is built in DWA Coin core
                        software distribution and accessible through a local web
                        browser. The client provides full support for all core DWA Coin
                        features, implemented such that users’ private keys are
                        never exposed to the network. It also includes an advanced
                        administrative interface and built-in Javadoc documentation for
                        DWA Coin Low-level Applications Programming Interface.
                        </p>
                    </section>

                    <section id="Agilearchitecture">
                        <h2 style={{textAlign: 'justify'}}>
                        Agile architecture
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        Cryptocurrencies were primarily designed as payment systems. DWA
                        Coin recognizes that decentralized blockchains could be
                        leveraged to broader range of applications and services but is
                        not prescriptive about what those services should be or how they
                        should be built. By design, DWA Coin strips away unnecessary
                        complexity in its core, leaving only the most successful
                        components of its predecessors intact. As a result, DWA Coin
                        functions like a low-level, foundational protocol: it defines
                        the interfaces and operations required to operate a lightweight
                        blockchain, a decentralized communication system, and a rapid
                        transaction processing framework, allowing higher-order
                        components to build on those features.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        DWA Coin make simple adjustments to account balances based on
                        every Transactions instead of tracing sets of input or output
                        credits. The core software does not support any form of
                        scripting language. DWA Coin creates a foundation that does not
                        limit the ways in which those transaction types can be used and
                        does not create significant overhead for using them, by
                        providing a set of basic, flexible transaction types that can
                        quickly and easily be processed. This flexibility is further
                        amplified by DWA Coin low resource and energy requirements, and
                        its highly readable, highly organized object-oriented source.
                        </p>
                    </section>

                    <section id="BasicPayments">
                        <h2 style={{textAlign: 'justify'}}>Basic Payments</h2>
                        <p style={{textAlign: 'justify'}}>
                        The most fundamental feature of any cryptocurrency is the
                        ability to transmit tokens from one account to another. This is
                        DWA Coin most fundamental transaction type, and it allows for
                        basic payment functionality.
                        </p>
                    </section>

                    <section id="AliasSystem">
                        <h2 style={{textAlign: 'justify'}}>Alias System</h2>
                        <p style={{textAlign: 'justify'}}>
                        The DWA Coin Alias System allows any string of text to be
                        permanently associated with a specific DWA Coin account. Since
                        its inception, a convention for the format of these strings,
                        using JSON notation, has been formalized. As a result, an alias
                        can currently be human-friendly text alias for an account
                        address or a Uniform Resource Identifier (URI).
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The ability to store any URI on the DWA Coin blockchain enables
                        the creation of any number of decentralized services that rely
                        on small, persistent strings of text, such as a distributed
                        Domain Name Server (DNS) system.
                        </p>
                    </section>

                    <section id="ArbitraryMessages">
                        <h2 style={{textAlign: 'justify'}}>
                        Arbitrary Messages
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        By using the Arbitrary Messages feature, DWA Coin blockchain
                        able to store strings of data up to 1000 bytes in length, and
                        these strings may optionally be AES-encrypted. These messages
                        are intended to be removable, in the future, when blockchain
                        size needs to be reduced; nonetheless, they form a critical
                        building block for several next-generation features.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        At the basic level, the system can transmit human-readable
                        messages between accounts, creating a decentralized chat system.
                        However, advanced applications can use this feature to store
                        structured data, such as JSON objects, that can be used to
                        trigger or facilitate services built on top of DWA Coin. The
                        most notable current implementation is the DWA Coin Multi
                        gateway (MGW), part of the DWA Coin Services layer, which
                        employs the Arbitrary Messaging system to drive a
                        nearly-trustless method for automatically transforming Bitcoin,
                        Litecoin, and other cryptocurrencies into DWA Coin assets (based
                        on the colored coins concept) that can be traded, bought, and
                        sold on the fully-decentralized asset exchange.
                        </p>
                    </section>

                    <section id="AssetExchange">
                        <h2 style={{textAlign: 'justify'}}>Asset Exchange</h2>
                        <p style={{textAlign: 'justify'}}>
                        An entire class of DWA Coin transactions is used to implement a
                        fully decentralized and automated asset exchange that operates
                        on the DWA Coin blockchain. Using the colored coins concept, DWA
                        Coin assets may be issued and tracked on the DWA Coin ecosystem,
                        supported by transactions and processing that allow for asset
                        transfer, bid and ask order placement, and automatic order
                        matching.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        By combining the features of the DWA Coin Asset Exchange with
                        other features such as the Arbitrary Messaging System,
                        value-added services can be created. Most notably, another
                        feature of the DWA Coin Services layer is a system for the
                        automated calculation and disbursement of dividends based on the
                        performance of existing DWA Coin assets.
                        </p>
                    </section>

                    <section id="DigitalGoodsStore">
                        <h2 style={{textAlign: 'justify'}}>
                        Digital Goods Store
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        The DWA Coin Digital Goods store gives account owners the
                        ability to list assets for sale in an open, decentralized
                        marketplace. Goods can be purchased, discounted, delivered,
                        refunded, and transferred, using a dedicated class of
                        transaction types that manage and secure store listings on the
                        decentralized blockchain.
                        </p>
                    </section>

                    <section id="DevicePortability">
                        <h2 style={{textAlign: 'justify'}}>
                        Device Portability
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        Due to its cross-platform, Java-based roots, its Proof of Stake
                        hashing and its future ability to reduce the size of the block
                        chain, DWA Coin is extremely well suited for use on small,
                        low-power, low-resource devices. Android and iPhone applications
                        are currently in development, and the DWA Coin software has been
                        ported to low-powered ARM devices such as the RaspberryPi and
                        CubieTruck platforms.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The ability to implement DWA Coin on low-powered,
                        always-connected devices such as smartphones allows us to
                        envision a scenario where the majority of the DWA Coin network
                        is supported on mobile devices. The low cost and resource
                        consumption of these devices significantly reduce network costs
                        in comparison with traditional Proof of Work cryptocurrencies.
                        </p>
                    </section>

                    <h1 style={{textAlign: 'justify'}}>Concerns</h1>

                    <section id="ProofofStakeAttacks">
                        <h2 style={{textAlign: 'justify'}}>
                        Proof of Stake Attacks
                        </h2>
                    </section>

                    <section id="NothingatStake">
                    <h3 style={{textAlign: 'justify'}}>Nothing at Stake</h3>
                        <p style={{textAlign: 'justify'}}>
                        Nothing-at-stake is a theoretical security issue in
                        proof-of-stake consensus systems in which validators have a
                        financial incentive to mine on every fork of the blockchain that
                        takes place, which is disruptive to consensus and potentially
                        makes the system more vulnerable to attacks.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        While this attack is theoretically possible, it is currently not
                        practical. The DWA Coin network does not experience long
                        blockchain forks, and the low block reward does not provide a
                        strong profit incentive; further, compromising network security
                        and trust for the sake of such small gains would make any
                        victory pyrrhic.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        As part of DWA Coin development roadmap, a feature called
                        Economic Clustering will provide further protection against
                        attacks of this nature by forcing transactions to include hashes
                        of previous blocks, and by grouping nodes into clusters that can
                        detect unusual behavior on the network and impose penalties (in
                        the form of temporary loss of the ability to forge).
                        </p>
                    </section>

                    <section id="HistoryAttack">
                        <h3 style={{textAlign: 'justify'}}>History Attack</h3>
                        <p style={{textAlign: 'justify'}}>
                        In a history attack, someone acquires a large number of tokens,
                        sells them, and then attempts to create a successful fork from
                        just before the time when their tokens were sold or traded. If
                        the attack fails, the attempt costs nothing because the tokens
                        have already been sold or traded; if the attack succeeds, the
                        attacker gets their tokens back. Extreme forms of this attack
                        involve obtaining the private keys from old accounts and using
                        them to build a successful chain right from the genesis block.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        In DWA Coin, the basic history attack generally fails because
                        all stake must be stationary for 1440 blocks before it can be
                        used for forging; moreover, the effective balance of the account
                        that generates each block is verified as part of block
                        validation. The extreme form of this attack generally fails
                        because the DWA Coin blockchain cannot be re-organized more than
                        720 blocks behind the current block height. This limits the time
                        frame in which a bad actor could mount this form of attack.
                        </p>
                    </section>

                    <section id="TransactionFeess">
                        <h2 style={{textAlign: 'justify'}}>Transaction Fees</h2>
                        <p style={{textAlign: 'justify'}}>
                        The cost of minimum transactions fees, known as fiat, will
                        increases as the value of DWA Coin increases. Plans are underway
                        to reduce the minimum fee, scaled according to transaction
                        byte-size, in order for micro-transactions to be practical.
                        </p>
                    </section>

                    <section id="BitcoinProblemsAddressedbyDWACoin">
                        <h1 style={{textAlign: 'justify'}}>
                        Bitcoin Problems Addressed by DWA Coin
                        </h1>
                        <p style={{textAlign: 'justify'}}>
                        DWA Coin was created as a cryptocurrency 2.0 response to
                        Bitcoin. DWA Coin adopts features that have proved to work well
                        in Bitcoin, and addresses aspects that are cause for concern.
                        This appendix addresses issues with the Bitcoin protocol and
                        network that are mitigated by DWA Coin technology.
                        </p>
                    </section>

                    <section id="BlockchainSize">
                        <h2 style={{textAlign: 'justify'}}>
                        Blockchain Size
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        The Bitcoin blockchain is the complete sequential collection of
                        generated data blocks containing the electronic ledger book for
                        all Bitcoin transactions occurring since its launch in January
                        2009. Four years later in January 2013, the size of the Bitcoin
                        blockchain stood at 4 gigabytes (GB) about the amount of data
                        required to store a two-hour movie on a DVD disk. Eighteen
                        months later, in July 2014, the size of the Bitcoin blockchain
                        had swelled by almost a factor of five to 19 gigabytes (GB). The
                        Bitcoin blockchain is undergoing exponential growth and
                        modifications to the original Bitcoin protocol will be required
                        to deal with it.
                        </p>
                    </section>

                    <section id="DWACoinSolutions">
                        <h3 style={{textAlign: 'justify'}}>DWA Coin Solutions</h3>
                        <p style={{textAlign: 'justify'}}>
                        DWA Coin block size is currently capped at 32KB. Since its
                        inception, almost 181,000 blocks have been generated and the
                        blockchain takes up 390MB of space. In the future, DWA Coin will
                        implement a Blockchain Pruning feature (still under discussion)
                        that will reduce blockchain size by selectively removing
                        information on permanent blocks, and by deleting other
                        non-persistent data, such as Arbitrary Messages.
                        </p>
                    </section>
                    <section id="TransactionsperDay">
                        <h2 style={{textAlign: 'justify'}}>
                        Transactions per Day
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        In late 2019, the number of transactions being processed on the
                        Bitcoin network was peaking at 70,000 per day, which is about
                        0.8 transactions per second (tps). The current Bitcoin standard
                        block size of one megabyte, generated every ten minutes (on
                        average) by full node clients, limits the maximum capacity of
                        the current Bitcoin network to a about 7 tps. Compare this with
                        the VISA network’s capacity to handle 10,000 tps and you
                        will see that Bitcoin cannot compete as it exists today.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        Increasing public use of the Bitcoin system will cause Bitcoin
                        to soon hit its transaction-per-day limit and halt further
                        growth. To forestall this, Bitcoin software developers are
                        working on the creation of thin clients that employ simplified
                        payment verification (SPV). To handle greater throughput in the
                        same 10-minute-average time, SPV thin clients will not perform a
                        full security check on the larger blocks they process. They will
                        instead examine multiple hashed blockchains from competing
                        miners and assume that the blockchain version generated by the
                        majority of miners is correct. In the words of Bitcoins Mike
                        Hearn, instead of verifying the entire contents, [SPV] just
                        trusts that the majority of miners are honest…. As long
                        as the majority is honest, [SPV] works… [However], the
                        full node does give you better security. If you’re running
                        an online shop for example, it makes sense to run a full node.
                        </p>
                    </section>
                    <section id="DWACoinSolutionss">
                        <h3 style={{textAlign: 'justify'}}>DWA Coin Solutions</h3>
                        <p style={{textAlign: 'justify'}}>
                        In its current state, the DWA Coin network can process up to
                        720,000 transactions per day more than nine times Bitcoins
                        current peak values. The planned implementation of Transparent
                        Forging will allow for near instant transaction processing,
                        drastically increasing this limit.
                        </p>
                    </section>

                    <section id="TransactionConfirmationTime">
                        <h2 style={{textAlign: 'justify'}}>
                        Transaction Confirmation Time
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        Transaction confirmation times for Bitcoin ranged from 5 to 10
                        minutes for most of 2013. After the late 2013 announcement that
                        Chinese banks would not be allowed to process Bitcoins, the
                        average Bitcoin transaction time significantly increased to 8 to
                        13 minutes, with occasional peaks of 19 minutes. Confirmation
                        times have since resettled in the 8 to 10-minute range.
                        Nonetheless, since multiple verifications are required to
                        finalize a Bitcoin transaction (six confirmations are generally
                        preferred), one hour can easily pass before a sale of assets
                        paid for by Bitcoin is complete.
                        </p>
                    </section>
                    <section id="DWACoinSolutionsss">
                        <h3 style={{textAlign: 'justify'}}>DWA Coin Solutions</h3>
                        <p style={{textAlign: 'justify'}}>
                        The average block generation time for DWA Coin has historically
                        been shown to be about 60 seconds, putting the average
                        transaction processing time at the same value. Transactions are
                        deemed safe after ten confirmations, meaning that transactions
                        are permanent in less than 30 minutes.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The implementation of Transparent Forging will allow for nearly
                        instant transactions, which will further reduce this time.
                        </p>
                    </section>

                    <section id="CentralizationConcerns">
                        <h2 style={{textAlign: 'justify'}}>
                        Centralization Concerns
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        The increasing difficultyand combined network hash rate for
                        Bitcoin has created a high barrier to entry for newcomers, and
                        diminished returns for existing mining rigs. The block reward
                        incentive employed by Bitcoin has driven the creation of large,
                        single-owner installations of dedicated mining hardware, as well
                        as the reliance on a small set of large mining pools. This has
                        resulted in a centralization effect, where large amounts of
                        mining power are concentrated in the control of a decreasing
                        number of people. Not only does this create the kind of power
                        structure that Bitcoin was designed to circumvent, but it also
                        presents the real possibility that a single mining operation or
                        pool could amass 51% of the network’s total mining power
                        and execute a 51% attack. Attacks requiring as little as 25% of
                        total network hashing power also exist.
                        </p>
                    </section>

                    <section id="DWACoinSolutionssss">
                        <h3 style={{textAlign: 'justify'}}>DWA Coin Solutions</h3>
                        <p style={{textAlign: 'justify'}}>
                        The incentives provided by DWA Coin Proof of Stake algorithm
                        provide a low Return on Investment of approximately 0.1%. Since
                        no new coins are generated with each block, there is no
                        additional mining reward that incentivizes combining efforts to
                        generate blocks. Data shows that the DWA Coin network has
                        remained highly decentralized since its inception: a large (and
                        growing) number of unique accounts are contributing blocks to
                        the network, and the top five accounts have generated 35% of the
                        total number of blocks.
                        </p>
                    </section>

                    <section id="ProofofWork’sResourceCosts">
                        <h2 style={{textAlign: 'justify'}}>
                        Proof of Work’s Resource
                        Costs
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        Confirming transactions for existing Bitcoins, and creating new
                        Bitcoins to go into circulation, requires enormous background
                        computing power that must operate continuously. This computing
                        power is provided by so-called mining rigs operated by miners.
                        Bitcoin miners compete among themselves to add the next
                        transaction block to the overall Bitcoin blockchain. This is
                        done by hashing - bundling all Bitcoin transactions occurring
                        over the past ten minutes and trying to encrypt them into a
                        block of data that also coincidentally has a certain number of
                        consecutive zeros in it. Most trial blocks generated by a
                        miner’s hashing effort don’t have this target number
                        of zeros, so they make a slight change and try again. A Trillion
                        attempts to find this winning block is called a gig hash, with a
                        mining rig being rated by how many gig hashes it can perform in
                        a second, denoted by <em>GH/sec</em>. A winning miner who is
                        first to generate the next needle-in-a-haystack,
                        cryptographically correct Bitcoin block currently receives a
                        reward of 25 newly-mined Bitcoins - a reward worth, at the time
                        of this writing, around $15,750USD. This competition among
                        miners, with its hefty reward, repeats itself over and over and
                        over every ten minutes or so. By early 2019 over 3500 bitcoins
                        per day are generated, worth around $2.2 Billion US dollars per
                        day.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        With so much money at stake, miners have supported a blistering
                        arms race in mining rig technology to better their odds of
                        winning. Originally Bitcoins were mined using the central
                        processing unit (CPU) of a typical desktop computer. Then the
                        specialized graphics processing unit (GPU) chips in high-end
                        video cards were used to increase speeds. Field programmable
                        gate array (FPGA) chips were pressed into service next, followed
                        by mining rigs specialized application specific integrated
                        circuits (ASIC) chips. ASIC technology is the top of the line
                        for Bitcoin miners, but the arms race continues with various
                        generations of ASIC chips now coming into service. The current
                        generation of ASIC chips are the so-called 28nm units, based on
                        the size of their microscopic transistors in nanometers. These
                        are due to be replaced by 20nm ASIC units by late-2014. An
                        example of an upcoming state-of-the-art mining rig would be a
                        Butterfly Labs Monarch 28nm ASIC card, which is to provide
                        600GH/sec for an electricity consumption of 350 watts and a
                        price of $2200USD.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The mining rig infrastructure currently in place to support
                        ongoing Bitcoin operations is astounding. Bitcoin ASICs are like
                        autistic savants - they can do only the Bitcoin block
                        calculation and nothing more, but they can do that one
                        calculation at supercomputer speeds. In November 2013, Forbes
                        magazine ran an article entitled, Global Bitcoin Computing Power
                        Now 256 Times Faster Than Top 500 Supercomputers, Combined. In
                        mid-January 2014, statistics maintained at blockchain.info
                        showed that ongoing support of Bitcoin operations required a
                        continuous hash rate of around 18 Billion GH/sec. For one day,
                        that much hashing power produced 1.5 trillion trial blocks that
                        were generated and rejected by Bitcoin miners looking for one
                        the magic 144 blocks that would let them $2.2 Billion USD.
                        Almost all Bitcoin computations do not go towards curing cancer
                        by modeling DNA or to searching for radio signals from E.T.;
                        instead, they are totally wasted computations.
                        </p>
                        <p style={{textAlign: 'justify'}}>
                        The power and cost involved in this wasteful background mining
                        support of Bitcoin is enormous. If all Bitcoin mining rigs had
                        Monarch levels of capability as described above - which they
                        will not, until they are upgraded - they would represent a pool
                        of 30,000 machines costing over $63 Billion USD and consuming
                        over 10 megawatts of continuous power while running up an
                        electricity bill of over $3.5 Billion USD per day. The real
                        numbers are significantly higher for the current, less-efficient
                        mining rig pool of machines actually supporting Bitcoin today.
                        And these numbers are currently headed upward in an exponential
                        growth curve as Bitcoin marches from its current one transaction
                        per second to its current maximum of seven transactions per
                        second.
                        </p>
                    </section>

                    <section id="DWACoinSolutionsssss">
                        <h3 style={{textAlign: 'justify'}}>DWA Coin Solutions</h3>
                        <p style={{textAlign: 'justify'}}>
                        Analysis of the cost and energy efficiency of the DWA Coin
                        network shows that the entire DWA Coin ecosystem can be
                        maintained for about $60,000USD per year, which is currently
                        almost 2,200 times less expensive than the cost of running the
                        Bitcoin network.
                        </p>
                    </section>

                    <section id="ProofofWork’sResourceCostsPertainingtoCoinholders">
                        <h2 style={{textAlign: 'justify', paddingRight: 50}}>
                        Proof of Work’s Resource
                        Costs Pertaining to Coin holders
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        In addition to massive electrical costs, there is a hidden fee
                        for simply holding Bitcoins. For each block found, the entity
                        that generates the block receives a stipend. At the time of
                        writing, this stipend is 25 BTC, producing 10% inflation in the
                        total Bitcoin supply this year alone. For each $1000USD worth of
                        Bitcoin someone owns, that person is paying $100USD per Bitcoin
                        this year to pay miners for keeping the network secure.
                        </p>
                    </section>

                    <section id="DWACoinSolutionssssss">
                        <h3 style={{textAlign: 'justify'}}>DWA Coin Solution</h3>
                        <p style={{textAlign: 'justify'}}>
                        Since the complete supply of DWA Coin 1 Trillion coins was
                        created with the genesis block, there is no inflation in DWA
                        Coin. Deflationary pressures are likely to affect DWA Coin in
                        the future, and a planned feature called Ant deflation (design
                        in progress) will address that problem.
                        </p>
                    </section>

                    <h1 style={{textAlign: 'justify'}}>
                    Application and Future works
                    </h1>

                    <section id="Application">
                        <h2 style={{textAlign: 'justify'}}>
                        Application
                        </h2>
                        <p style={{textAlign: 'justify'}}>
                        DWA Coin could be implemented in such business model such as
                        Financial Service and Digital Payment system
                        </p>
                    </section>

                    <section id="AssetInvestment">
                        <h3 style={{textAlign: 'justify'}}>
                        Asset Investment
                        </h3>
                        <p style={{textAlign: 'justify'}}>
                        DWA Coin designed for develop a stable coin environment. Every
                        asset could be trade on top of DWA platform. The token trade
                        instead of the coin, will make the coin value stable.
                        </p>
                    </section>

                    <section id="CryptoExchange">
                        <h3 style={{textAlign: 'justify'}}>
                        Crypto Exchange
                        </h3>
                        <p style={{textAlign: 'justify'}}>
                        DWA Coin provide the flexibility to exchange to Coin/Fiat and
                        reverse.&nbsp; Beside to manage the liquidity of the investment,
                        this also open opportunity for everyone to manage the fund in
                        other form such as coin, rather than saving it in the bank.
                        </p>
                    </section>

                    <section id="Futureworks">
                        <h2 style={{textAlign: 'justify'}}>
                        Future works
                        </h2>
                        <p style={{textAlign: 'justify'}}>Currently DWA coin can be
                        exchanged directly to cryto such as USDT, in the future, DWA
                        will be developed more function to be able to exchange with
                        other currencies including other crypto currency such as BTC,
                        etc</p>
                    </section>
                </div>
                <div className="col-md border-start">
                    <div className="sidebar">
                        <p>ON THIS PAGE</p>
                        <ul>
                            <ListMenu/>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Home;
