import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './components/style.css';

import Home from './components/home/Home';

const App = () => {
	return(
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Home/>}/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;