import { Link } from 'react-scroll';

const ListMenu = () => {
    return(
        <>
            <li><Link activeClass="active" smooth spy to="TechnologyBackground">Technology Background</Link></li>
            <li><Link activeClass="active" smooth spy to="Purpose">Purpose</Link></li>
            <li>Coin Distribution</li>
            <ul>
                <li><Link activeClass="active" smooth spy to="CoinAllocations">Coin Allocations</Link></li>
                <li><Link activeClass="active" smooth spy to="GenesisAccounts">Genesis Accounts</Link></li>
            </ul>
            <li>Core technologies</li>
            <ul>
                <li><Link activeClass="active" smooth spy to="ProofofStake">Proof of Stake</Link></li>
                <li><Link activeClass="active" smooth spy to="DWACoin’sProofofStakeModel">DWA Coin’s Proof of Stake Model</Link></li>
                <li><Link activeClass="active" smooth spy to="Tokens">Tokens</Link></li>
                <li><Link activeClass="active" smooth spy to="NetworkNodes">Network Nodes</Link></li>
                <li><Link activeClass="active" smooth spy to="Blocks">Blocks</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="BlockCreation(Forging)">Block Creation (Forging)</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="BaseTargetValue">Base Target Value</Link></li>
                <li><Link activeClass="active" smooth spy to="TargetValue">Target Value</Link></li>
                <li><Link activeClass="active" smooth spy to="Cumulativedifficulty">Cumulative difficulty</Link></li>
                <li><Link activeClass="active" smooth spy to="TheForgingAlgorithm">The Forging Algorithm</Link></li>
                <li><Link activeClass="active" smooth spy to="Balanceleasing">Balance leasing</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="Accounts">Accounts</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="AccountBalanceProperties">Account Balance Properties</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="Transactions">Transactions</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="TransactionFees">Transaction Fees</Link></li>
                <li><Link activeClass="active" smooth spy to="TransactionConfirmations">Transaction Confirmations</Link></li>
                <li><Link activeClass="active" smooth spy to="TransactionDeadlines">Transaction Deadlines</Link></li>
                <li><Link activeClass="active" smooth spy to="TransactionTypes">Transaction Types</Link></li>
                <li><Link activeClass="active" smooth spy to="TransactionCreationandProcessing">Transaction Creation and Processing</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="CryptographicFoundations">Cryptographic Foundations</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="EncryptionAlgorithm">Encryption Algorithm</Link></li>
            </ul>
            <li>Core Features</li>
            <ul>
                <li><Link activeClass="active" smooth spy to="AdvancedJavaScriptclient">Advanced JavaScript client</Link></li>
                <li><Link activeClass="active" smooth spy to="Agilearchitecture">Agile architecture</Link></li>
                <li><Link activeClass="active" smooth spy to="BasicPayments">Basic Payments</Link></li>
                <li><Link activeClass="active" smooth spy to="AliasSystem">Alias System</Link></li>
                <li><Link activeClass="active" smooth spy to="ArbitraryMessages">Arbitrary Messages</Link></li>
                <li><Link activeClass="active" smooth spy to="AssetExchange">Asset Exchange</Link></li>
                <li><Link activeClass="active" smooth spy to="DigitalGoodsStore">Digital Goods Store</Link></li>
                <li><Link activeClass="active" smooth spy to="DevicePortability">Device Portability</Link></li>
            </ul>
            <li>Concerns</li>
            <ul>
                <li><Link activeClass="active" smooth spy to="ProofofStakeAttacks">Proof of Stake Attacks</Link></li>
                <li><Link activeClass="active" smooth spy to="NothingatStake">Nothing at Stake</Link></li>
                <li><Link activeClass="active" smooth spy to="HistoryAttack">History Attack</Link></li>
                <li><Link activeClass="active" smooth spy to="TransactionFeess">Transaction Fees</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="BitcoinProblemsAddressedbyDWACoin">Bitcoin Problems Addressed by DWA Coin</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="BlockchainSize">Blockchain Size</Link></li>
                <li><Link activeClass="active" smooth spy to="DWACoinSolutions">DWA Coin Solutions</Link></li>
                <li><Link activeClass="active" smooth spy to="TransactionsperDay">Transactions per Day</Link></li>
                <li><Link activeClass="active" smooth spy to="DWACoinSolutionss">DWA Coin Solutions</Link></li>
                <li><Link activeClass="active" smooth spy to="TransactionConfirmationTime">Transaction Confirmation Time</Link></li>
                <li><Link activeClass="active" smooth spy to="DWACoinSolutionsss">DWA Coin Solutions</Link></li>
                <li><Link activeClass="active" smooth spy to="CentralizationConcerns">Centralization Concerns</Link></li>
                <li><Link activeClass="active" smooth spy to="DWACoinSolutionssss">DWA Coin Solutions</Link></li>
                <li><Link activeClass="active" smooth spy to="ProofofWork’sResourceCosts">Proof of Work’s Resource Costs</Link></li>
                <li><Link activeClass="active" smooth spy to="DWACoinSolutionsssss">DWA Coin Solutions</Link></li>
                <li><Link activeClass="active" smooth spy to="ProofofWork’sResourceCostsPertainingtoCoinholders">Proof of Work’s Resource Costs Pertaining to Coin holders</Link></li>
                <li><Link activeClass="active" smooth spy to="DWACoinSolutionssssss">DWA Coin Solution</Link></li>
            </ul>
            <li>Application and Future works</li>
            <ul>
                <li><Link activeClass="active" smooth spy to="Application">Application</Link></li>
                <li><Link activeClass="active" smooth spy to="AssetInvestment">Asset Investment</Link></li>
                <li><Link activeClass="active" smooth spy to="CryptoExchange">Crypto Exchange</Link></li>
                <li><Link activeClass="active" smooth spy to="Futureworks">Future works</Link></li>
            </ul>
        </>
    );
}

export default ListMenu;